import $ from 'jquery'
const $doc = $(document)

function scrollDown(event) {
  let next = $(this).parent().parent().parent().next('.building-block');
  event.preventDefault()
  $([document.documentElement, document.body]).animate({
    scrollTop: $(next).offset().top - 100
  }, 500);
}

export default function initDownButton() {
  $doc.on('click', '.js-down', scrollDown)
}